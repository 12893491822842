import React from 'react'

// Components
import Gallery from './Gallery'
import GalleryText from './GalleryText'
import Show from './Show'

interface GalleryShellProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Gallery
  location?: any
}

interface GalleryProps {
  [key: string]: any
}

const GalleryShell: React.FC<GalleryShellProps> = ({
  fields,
  location = {},
}) => {
  const types: GalleryProps = {
    default: Gallery,
    gallery_text: GalleryText,
    show: Show,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default GalleryShell
