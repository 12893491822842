/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { Navigation } from 'swiper/modules'

// Library
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'

// Lightbox
import Lightbox from 'react-awesome-lightbox'
import 'react-awesome-lightbox/build/style.css'

// Elements
import Plaatjie from '@ubo/plaatjie'

const LightboxWrapper = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
`

const Image = styled(Plaatjie)`
  height: 733px;
  width: 100%;
  object-fit: cover;
`

interface GalleryProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Gallery
}

const Gallery: React.FC<GalleryProps> = ({ fields }) => {
  const [openIndex, setOpenIndex] = React.useState<null | number>(null)
  const images: any = []

  fields.gallery?.forEach((field) => {
    images.push({
      url: field?.localFile?.childImageSharp?.original?.src,
      sizes: field?.title,
    })
  })

  const first = fields?.gallery?.slice(0, 4)
  const slider = fields?.gallery?.slice(4, fields?.gallery?.length)

  let modulusIndex = 0

  return (
    <section>
      <div className="container py-5 my-lg-5">
        <div className="row">
          {first?.map((grid: any, index: number) => {
            let size = 'm'

            if (index !== 0 && index % 4 === 0) {
              modulusIndex = modulusIndex === 0 ? 1 : 0
            }

            if (index % 3 === modulusIndex) {
              size = 's'
            }

            return (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={`col-${size === 's' ? '5' : '7'} col-lg-${
                  size === 's' ? '8' : '4'
                } mb-4`}
              >
                <Grid item={grid} index={index} setOpenIndex={setOpenIndex} />
              </div>
            )
          })}
          <div>
            <Swiper
              modules={[Navigation]}
              autoplay={{ delay: 5000 }}
              slidesPerView={1}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
            >
              {slider?.map((slide, index: number) => (
                <SwiperSlide
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                >
                  <div
                    className="d-flex justify-content-center align-items-center h-100"
                    onClick={() => {
                      setOpenIndex(index)
                    }}
                    role="button"
                  >
                    <Image image={slide} alt="" />
                  </div>
                </SwiperSlide>
              ))}
              <div className="swiper-button-prev">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="45"
                  height="45"
                  viewBox="0 0 45 45"
                  fill="none"
                >
                  <g
                    clipPath="url(#clip0_634_128)"
                    filter="url(#filter0_d_634_128)"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M24.2344 30.1602C24.5234 30.4492 24.957 30.5937 25.3906 30.5937C26.4023 30.5937 27.125 29.8711 27.125 28.8594C27.125 28.2812 26.8359 27.8477 26.5469 27.5586L21.0547 22.5L26.5469 17.4414C26.8359 17.1523 27.125 16.7187 27.125 16.1406C27.125 15.1289 26.4023 14.4062 25.3906 14.4062C24.957 14.4062 24.5234 14.5508 24.2344 14.8398L17.2969 21.1992C17.0078 21.4883 16.7188 21.9219 16.7188 22.5C16.7188 23.0781 16.8633 23.5117 17.2969 23.8008L24.2344 30.1602ZM22.5 41C32.7617 41 41 32.7617 41 22.5C41 12.2383 32.7617 4 22.5 4C12.2383 4 4 12.2383 4 22.5C4 32.7617 12.2383 41 22.5 41ZM22.5 7.46875C30.7383 7.46875 37.5313 14.2617 37.5313 22.5C37.5313 30.7383 30.7383 37.5312 22.5 37.5312C14.2617 37.5312 7.46875 30.7383 7.46875 22.5C7.46875 14.2617 14.2617 7.46875 22.5 7.46875Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_634_128"
                      x="0"
                      y="0"
                      width="45"
                      height="45"
                      filterUnits="userSpaceOnUse"
                      color-colorInterpolationFilters-filters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_634_128"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_634_128"
                        result="shape"
                      />
                    </filter>
                    <clipPath id="clip0_634_128">
                      <rect
                        width="37"
                        height="37"
                        fill="white"
                        transform="translate(41 41) rotate(-180)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="swiper-button-next">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="45"
                  height="45"
                  viewBox="0 0 45 45"
                  fill="none"
                >
                  <g
                    clipPath="url(#clip0_634_125)"
                    filter="url(#filter0_d_634_125)"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M20.7656 14.8398C20.4766 14.5508 20.043 14.4062 19.6094 14.4062C18.5977 14.4062 17.875 15.1289 17.875 16.1406C17.875 16.7187 18.1641 17.1523 18.4531 17.4414L23.9453 22.5L18.4531 27.5586C18.1641 27.8477 17.875 28.2813 17.875 28.8594C17.875 29.8711 18.5977 30.5937 19.6094 30.5937C20.043 30.5937 20.4766 30.4492 20.7656 30.1602L27.7031 23.8008C27.9922 23.5117 28.2812 23.0781 28.2812 22.5C28.2812 21.9219 28.1367 21.4883 27.7031 21.1992L20.7656 14.8398ZM22.5 4C12.2383 4 4 12.2383 4 22.5C4 32.7617 12.2383 41 22.5 41C32.7617 41 41 32.7617 41 22.5C41 12.2383 32.7617 4 22.5 4ZM22.5 37.5312C14.2617 37.5312 7.46875 30.7383 7.46875 22.5C7.46875 14.2617 14.2617 7.46875 22.5 7.46875C30.7383 7.46875 37.5312 14.2617 37.5312 22.5C37.5313 30.7383 30.7383 37.5312 22.5 37.5312Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_634_125"
                      x="0"
                      y="0"
                      width="45"
                      height="45"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_634_125"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_634_125"
                        result="shape"
                      />
                    </filter>
                    <clipPath id="clip0_634_125">
                      <rect
                        width="37"
                        height="37"
                        fill="white"
                        transform="translate(4 4)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </Swiper>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {openIndex !== null && (
          <LightboxWrapper
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{
              duration: 0.2,
            }}
          >
            <Lightbox
              images={images}
              startIndex={openIndex}
              onClose={() => {
                setOpenIndex(null)
              }}
              doubleClickZoom={2}
              showTitle={false}
            />
          </LightboxWrapper>
        )}
      </AnimatePresence>
    </section>
  )
}

const ColImage = styled(Plaatjie)`
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  height: 100%;
  width: 100%;

  & img {
    object-fit: cover;
    max-height: 456px;
  }
`

interface GridProps {
  item: any
  index: number
  // eslint-disable-next-line no-unused-vars
  setOpenIndex: (index: number) => void
}

const Grid: React.FC<GridProps> = ({ item, index, setOpenIndex }) => (
  <div
    role="button"
    className="h-100"
    onClick={() => {
      setOpenIndex(index)
    }}
  >
    <ColImage
      className="w-100 h-100 cursor-pointer"
      image={item}
      alt="VT Tuinen"
    />
  </div>
)

export default Gallery
