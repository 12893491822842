/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

// Library
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'

// Lightbox
import Lightbox from 'react-awesome-lightbox'
import 'react-awesome-lightbox/build/style.css'

// Elements
import Plaatjie from '@ubo/plaatjie'

const LightboxWrapper = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
`

interface ShowProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Gallery
}

const Show: React.FC<ShowProps> = ({ fields }) => {
  const [openIndex, setOpenIndex] = React.useState<null | number>(null)
  const images: any = []

  fields.gallery?.forEach((field) => {
    images.push({
      url: field?.localFile?.childImageSharp?.original?.src,
      sizes: field?.title,
    })
  })

  let modulusIndex = 0

  return (
    <section className="py-lg-5">
      <div className="container py-5">
        <div className="row py-lg-5">
          {fields?.gallery?.map((grid: any, index: number) => {
            let size = 'm'

            if (index !== 0 && index % 4 === 0) {
              modulusIndex = modulusIndex === 0 ? 1 : 0
            }

            if (index % 3 === modulusIndex) {
              size = 's'
            }

            return (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={`col-md-${
                  size === 's' ? '8' : '4'
                } mb-4`}
              >
                <Grid item={grid} index={index} setOpenIndex={setOpenIndex} />
              </div>
            )
          })}
        </div>
      </div>
      <AnimatePresence>
        {openIndex !== null && (
          <LightboxWrapper
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{
              duration: 0.2,
            }}
          >
            <Lightbox
              images={images}
              startIndex={openIndex}
              onClose={() => {
                setOpenIndex(null)
              }}
              doubleClickZoom={2}
              showTitle={false}
            />
          </LightboxWrapper>
        )}
      </AnimatePresence>
    </section>
  )
}

const ColImage = styled(Plaatjie)`
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  height: 100%;
  width: 100%;

  & img {
    object-fit: cover;
    max-height: 456px;
  }
`

interface GridProps {
  item: any
  index: number
  // eslint-disable-next-line no-unused-vars
  setOpenIndex: (index: number) => void
}

const Grid: React.FC<GridProps> = ({ item, index, setOpenIndex }) => (
  <div
    role="button"
    className="h-100"
    onClick={() => {
      setOpenIndex(index)
    }}
  >
    <ColImage
      className="w-100 h-100 cursor-pointer"
      image={item}
      alt="VT Tuinen"
    />
  </div>
)

export default Show
